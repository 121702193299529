import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PostIndexItem from "../components/PostIndexItem";

const IndexPage = ({ data }) => (
    <Layout>
        <SEO title="Home" />

        {/*{JSON.stringify(data)}*/}

        <div className="post p2 p-responsive wrap" role="main">
            <div className="measure">
                <div className="home">
                    <div className="posts">
                        {data.allMarkdownRemark.edges.map(post => (
                            <PostIndexItem
                                key={post.node.id}
                                excerpt={post.node.excerpt}
                                path={post.node.frontmatter.path}
                                title={post.node.frontmatter.title}
                                date={post.node.frontmatter.date}
                            />
                        ))}
                    </div>

                    <div className="pagination clearfix mb1 mt4">
                        <div className="left">
                            <span className="pagination-item disabled">Newer</span>
                        </div>

                        <div className="right">
                            <span className="pagination-item disabled">Older</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);

export const query = graphql`
    query {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { published: { eq: true } } }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        path
                        date(fromNow: true)
                    }
                    excerpt
                }
            }
            totalCount
            pageInfo {
                currentPage
                hasNextPage
                hasPreviousPage
                pageCount
                itemCount
                perPage
            }
        }
    }
`;

export default IndexPage;
