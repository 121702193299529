import React from "react";
import { Link } from "gatsby";

const PostIndexItem = ({ title, date, excerpt, path }) => (
    <div className="post py3">
        <p className="post-meta">{date}</p>

        <Link to={path} className="post-link">
            <h3 className="h1 post-title">{title}</h3>
        </Link>

        <p className="post-summary">{excerpt}</p>
    </div>
);

export default PostIndexItem;